import React, { useEffect, useRef, useState } from 'react';
import './MainInput.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MainButton } from '../../Buttons/MainButton/MainButton';
import { useClickOutside } from '../../../Hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import { FormInputProps } from '../../../Hooks/useFormInput';


interface MainInputButton {
    content: string | IconDefinition;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => any
}
export type MainInputProps = {
    /** Placeholder for the input */
    placeholder?: string;
    /** Description for the input */
    description?: string;
    /** Does the input show the edit button? */
    editable?: boolean;
    /** Is the input disabled? */
    disabled?: boolean;
    /** Action button for the input */
    button?: MainInputButton;
    /** Content type of the button */
    type?: React.HTMLInputTypeAttribute;
    /** Add stop propagation on actions */
    stopPropagation?: boolean;
    /** Is the input required? */
    required?: boolean;
    /** Max value for a numeric input */
    max?: string | number;
    /** Min value for a numeric input */
    min?: string | number;
    /** Should we check that the input is a valid email? */
    isEmail?: boolean;
    /** Show the input as a plain text */
    asText?: boolean;
    /** Stateful data for the input (value, status, hint) */
    props: FormInputProps<any>;
    name?: string;
    /**
     * Function that runs when pressing the save button in the input.
     * Can return false if the result should not reset the input
     */
    onSave?: (event: React.MouseEvent<HTMLButtonElement> | undefined) => any | Promise<any>;
    /** More actions on change */
    moreOnChange?: ((event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void)[];
    formatText?: (value: string) => string;
    step?: React.InputHTMLAttributes<HTMLInputElement>['step'];
    textarea?: boolean;
    autocomplete?: 'on' | 'off';
};

export const MainInput: React.FC<MainInputProps> = ({
    placeholder = '',
    description = '',
    editable = false,
    button = null,
    type = 'text',
    stopPropagation = false,
    required = false,
    max = undefined,
    min = undefined,
    isEmail = false,
    asText = false,
    props,
    onSave = null,
    moreOnChange = [],
    formatText = null,
    step = undefined,
    name = 'main-input',
    textarea = false,
    autocomplete = 'off'
}) => {
    const { t } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);

    const inputContainerRef = useRef<any>(null);

    const { getValue, setValue, getOriginalValue, setOriginalValue, getHint, setHint, getDisabled, setDisabled, getStatus, setStatus } = props;
    
    const isDisabled = getDisabled();

    useClickOutside(inputContainerRef, () => {
        if (!isEditing) return;
        save();
    }, [isEditing, getValue(), getStatus(), getHint()]);

    const onEnterInput = (event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (button?.onClick) {
                button.onClick();
            }

            if (!isEditing) return;

            save();
        }
    };

    const getValidValue = (value: string) => {
        const parsedValue = parseInt(value);
        try {
            if (max) {
                const valMax = parseInt(`${max}`);

                if (parsedValue > valMax) {
                    return false;
                }
            }

            if (min) {
                const valMin = parseInt(`${min}`);

                if (parsedValue < valMin) {
                    return false;
                }
            }
        } catch (err) {
            return false;
        }

        return true;
    };

    const checkEmail = (email: string) => {
        if (!isEmail) {
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);

        if (!isValidEmail) {

            setHint(t('email.error.invalidEmail'));

            setStatus('ERROR');
            return;
        }
    };

    const checkRequired = (value: string) => {
        if (!required || value) {
            setStatus('NORMAL');
            return;
        }

        setHint(t('t.thisFieldIsRequired'));

        setStatus('ERROR');
        return;
    };

    const checkValue = (curValue: string) => {
        let validValue = undefined;

        validValue = getValidValue(curValue);

        if (!validValue) {
            setStatus('ERROR');
            return curValue;
        }

        checkRequired(curValue);

        checkEmail(curValue);

        return curValue;
    };

    const handleOnChange = (
        event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
    ) => {
        const target = event.currentTarget;
        const targetValue = target.value;

        const newValue = checkValue(targetValue);

        setValue(newValue);

        moreOnChange.forEach((onChange) => {
            onChange(event);
        });

        if (getStatus() == 'ERROR') {
            return false;
        }
    };

    const toggleEdit = (newStatus: boolean) => {
        if (!editable) return;

        let valueSet = getValue();

        if (!newStatus) {
            setValue(getOriginalValue());
            valueSet = getOriginalValue();
        }

        checkValue(valueSet);

        setIsEditing(newStatus);
        setDisabled(!newStatus);
    };

    const handleOnClickToggleEdit = (
        event?: React.FormEvent<HTMLButtonElement | HTMLDivElement>
    ) => {
        if (event && stopPropagation) {
            event.stopPropagation();
        }

        const newStatus = !isEditing;

        toggleEdit(newStatus);
    };

    /**
     * Handles the pressing of the save button, and resets the input
     * back to normal. If the onSave function returns false, nothing
     * changes
     * 
     * @param event Triggered event
     * @returns 
     */
    const handleOnClickSave = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (stopPropagation) {
            event.stopPropagation();
        }
        if (getStatus() == 'ERROR') return;

        save(event);
    };

    const save = async (
        event: React.MouseEvent<HTMLButtonElement> | undefined = undefined
    ) => {
        if (!isEditing) { return; }

        const sameValue = getValue() === getOriginalValue();

        checkValue(getValue());

        if (getStatus() == 'ERROR') return;

        if (!sameValue && onSave) {
            const result = await onSave(event);

            if (result === false) return;
        }

        setIsEditing(false);
        setDisabled(true);
        setOriginalValue(getValue());

        return;
    };

    if (asText) {
        if (isEditing) {
            return (
                <div ref={inputContainerRef} className='text-input-container editing'>
                    <div className='input'>
                        {textarea ? (
                            <textarea
                                onChange={handleOnChange}
                                placeholder={placeholder}
                                value={getValue()}
                                name={name}
                            >
                                {getValue()}
                            </textarea>
                        ) : (
                            <input
                                onKeyDown={onEnterInput}
                                type={type}
                                value={getValue()}
                                onChange={handleOnChange}
                                placeholder={placeholder}
                                name={name}
                                autoComplete='off'
                            />
                        )}
                        <div className='text-input-buttons'>
                            <MainButton onClick={handleOnClickSave} classList={['check', 'no-shadow']}>
                                <FontAwesomeIcon icon={faCheck} />
                            </MainButton>
                            <MainButton onClick={handleOnClickToggleEdit} classList={['red', 'no-shadow']}>
                                <FontAwesomeIcon icon={faTimes} />
                            </MainButton>
                        </div>
                    </div>
                    {getStatus() == 'ERROR' && (
                        <div className='hint'>
                            {getHint()}
                        </div>
                    )}
                </div>

            );
        }

        let text = getValue();

        if (formatText) {
            text = formatText(text);
        }

        return (
            <div ref={inputContainerRef} className={`text-input-container ${editable ? '' : 'not-editable'}`} onClick={handleOnClickToggleEdit}>
                <div className='input'>
                    <span className='text'>
                        {text}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div ref={inputContainerRef} className={`main-input ${getStatus().toLowerCase()}`}>
            {description != '' && (
                <span className='description' title={description}>{description}</span>
            )}
            <div className="input-container">
                {textarea ? (
                    <textarea
                        onChange={handleOnChange}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        value={getValue()}
                        name={name}
                        autoComplete={autocomplete}
                    >
                        {getValue()}
                    </textarea>
                ) : (
                    <input
                        onKeyDown={onEnterInput}
                        type={type}
                        placeholder={placeholder}
                        onChange={
                            event => handleOnChange(event)
                        }
                        value={getValue()}
                        disabled={isDisabled}
                        min={min}
                        max={max}
                        step={step ? step : 'any'}
                        name={name}
                        autoComplete={autocomplete}
                    />
                )}
                {editable && !isEditing && (
                    <div className='input-buttons'>
                        <MainButton onClick={handleOnClickToggleEdit} >
                            <FontAwesomeIcon icon={faPencil} />
                        </MainButton>
                    </div>
                )}
                {editable && isEditing && (
                    <div className='input-buttons'>
                        <MainButton onClick={handleOnClickSave} classList={['check']} >
                            <FontAwesomeIcon icon={faCheck} />
                        </MainButton>
                        <MainButton onClick={handleOnClickToggleEdit} classList={['red']} >
                            <FontAwesomeIcon icon={faTimes} />
                        </MainButton>
                    </div>
                )}
                {button && (
                    <div className='input-icon'>
                        <MainButton onClick={button.onClick} classList={['no-border', 'no-shadow', 'full-width', 'full-height']}>
                            {typeof button.content == 'string' ? (
                                <>
                                    {button.content}
                                </>
                            ) : (
                                <FontAwesomeIcon icon={button.content} />
                            )}
                        </MainButton>
                    </div>
                )}
            </div>
            {getStatus() == 'ERROR' && (
                <div className='hint'>
                    {getHint()}
                </div>
            )}
        </div>
    );
};