import './App.css';
import { HashRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { Dashboard } from './Pages/Dashboard/Dashboard';
import { Navbar } from './Components/Navbar/Navbar';
import { useContext, useEffect, useRef, useState } from 'react';
import UserContext, { UserContextT, UserData, getUserCookie, setUserCookie } from './Context/UserContext';
import { NotificationItem, NotifyProps } from './Types/components.types';
import { Notification, NotificationPopup } from './Components/Notification/Notification';
import NotificationContext from './Context/NotificationContext';
import { DashboardAlt } from './Pages/Dashboard/DashboardAlt';
import { Watson } from './Components/Watson/Watson';




const Layout = () => {
	return (
		<>
			<Navbar />
			<Watson />
			<div className="layout">
				<Outlet />
			</div>
		</>
	);
};

function App() {
	const [userData, setUserData] = useState<UserData | null>(null);

	const notificationListRef = useRef<NotificationItem[]>([]);

	useEffect(() => {
		getUserContext();
	}, []);

	useEffect(() => {
		if (!userData) {
			return;
		}

		setUserCookie(userData);
	}, [userData]);

	const getUserContext = async () => {
		const userData = getUserCookie();

		setUserData(userData);
	};

	const notify = (props: NotifyProps) => {
		const notificationList = notificationListRef.current;

		console.log('notificationList', notificationList);


		const newNotification: NotificationItem = {
			id: notificationList.length,
			props: props,
			item: (
				<Notification
					type={props.type ? props.type : 'NORMAL'}
					text={props.text}
					closeType={props.closeType}
				/>
			)
		};

		const newNotificationList = notificationList.map((notification) => {
			const item: NotificationItem = {
				id: notification.id,
				props: notification.props,
				item: (
					notification.item
				)
			};

			return item;
		});

		newNotificationList.push(newNotification);

		notificationListRef.current = newNotificationList;
	};

	return (
		<NotificationContext.Provider
			value={{ notification: { notify, notificationList: notificationListRef } }}
		>
			<NotificationPopup />
			<UserContext.Provider value={{ userData, setUserData }}>
				<Router>
					<Routes>
						<Route path="/" index element={<DashboardAlt />} />
						{/* <Route path="/c/" element={<Layout />}>
							<Route path="*" element={<></>}></Route>
						</Route> */}
					</Routes>
				</Router>
			</UserContext.Provider>
		</NotificationContext.Provider>
	);
}

export default App;
