import React, { ReactNode, useContext, useEffect, useState } from 'react';

import './Notification.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faThumbsUp, faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import NotificationContext from '../../Context/NotificationContext';

export type NotificationPopupStatus =
	| 'NORMAL'
	| 'ERROR'
	| 'WARNING'
	| 'LOADING';

export type NotifyProps = {
	type?: NotificationPopupStatus;
	text: string;
	closeType?: NotificationClose;
};
export type NotificationItem = {
	id: number;
	props: NotifyProps;
	item: ReactNode;
};
export type NotificationCloseType = 'timeout' | 'manual';
export type NotificationClose = 
	{
		type: 'timeout';
		timeout: number;
	} | {
		type: 'manual';
	};

export type NotificationPopupProps = {
	notify: (props: NotifyProps) => void;
	notificationList: React.MutableRefObject<NotificationItem[]>;
};

interface NotificationProps {
    type: NotificationPopupStatus;
    text: string;
    closeType?: NotificationClose;
}
export const Notification: React.FC<NotificationProps> = ({ type, text, closeType = { type: 'timeout', timeout: 2500 } }) => {

    const [loaded, setLoaded] = useState(true);
    const [visible, setVisible] = useState(false);
    const [finished, setFinished] = useState(false);

    let icon = faThumbsUp;

    switch (type) {
        case 'WARNING':
            icon = faWarning;
            break;

        case 'ERROR':
            icon = faTimes;
            break;

        case 'LOADING':
            icon = faInfo;
            break;
    }

    // Show animation
    useEffect(() => {
        const showTimer = setTimeout(() => {
            setVisible(true);
            setLoaded(true);
        }, 250);

        return () => {
            clearTimeout(showTimer);
        };

    }, []);

    // Hide animation
    useEffect(() => {
        if (!loaded || closeType.type != 'timeout') return;

        const hideTimer = setTimeout(() => {
            setVisible(false);
            setLoaded(false);
        }, closeType.timeout);

        return () => {
            clearTimeout(hideTimer);
        };
    }, [loaded, closeType]);

    useEffect(() => {
        if (loaded) return;

        const closeTimeout = setTimeout(() => {
            setFinished(true);
        }, 500);

        return () => {
            clearTimeout(closeTimeout);
        };
    }, [loaded]);

    const onClickPopup = () => {
        setVisible(false);
        setLoaded(false);
    };

    if (finished) {
        return (<></>);
    }

    return (
        <div
            className={`notification-popup ${type.toLowerCase()} ${visible ? '' : 'hidden'} close-${closeType.type}`}
            onClick={onClickPopup}
        >
            <div className='content'>
                <span className='icon'>
                    <FontAwesomeIcon icon={icon} />
                </span>
                <span>
                    {text}
                </span>
            </div>
            <div className='status-bar'>

            </div>
        </div>
    );
};

export const NotificationPopup = () => {

    const { notification } = useContext(NotificationContext);

    const [notificationList, setNotificationList] = useState(notification.notificationList.current);

    useEffect(() => {
        const notificationInterval = setInterval(() => {
            if (notificationList != notification.notificationList.current) {
                setNotificationList(notification.notificationList.current);
            }
        }, 250);

        return () => {
            clearInterval(notificationInterval);
        };
    }, []);

    return (
        <div className='notification-container'>
            {notificationList.map((item) => {
                return (
                    <React.Fragment key={item.id}>
                        {item.item}
                    </React.Fragment>
                );
            })}
        </div>
    );
};