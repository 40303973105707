import axios, { AxiosRequestConfig } from 'axios';
import { NoiseReport } from '../../Types/models.types';
import ModelController from '../ModelController.controller';
import CommonReportController, { SaveCompleteReport } from './CommonReport.controller';
import { getTokenCookie } from '../../Utils/cookies.utils';

export default class NoiseReportController extends CommonReportController<NoiseReport> {
	constructor() {
		const objectName = 'noise_report';
		
		super(objectName);
	}

	public async createNoiseReport(
		newObject: Partial<SaveCompleteReport>,
		audio: Blob
	): Promise<NoiseReport> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			const formData = new FormData();

			console.log(audio);
			
			const audioFile = new File([audio], 'audio.webm');
			
			formData.append('file', audioFile, 'audio.webm');
			formData.append('report_data', JSON.stringify(newObject.reportData));
			formData.append('report_type_data', JSON.stringify(newObject.reportTypeData));

			config.headers = {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data;',
			};
			
			let response = undefined;

			response = await axios.post(
				`${this.api.url}/${this.objectName}`,
				formData,
				config
			);

			console.log(response);

			if (!response.data) {
				throw new Error(JSON.stringify(response));
			}

			return response.data;
		} catch (e: any) {
			const error = e;
			console.error('Error:' + e);

			/* if (e instanceof AxiosError) {
				if (e.response?.data?.error_data?.errors) {
					const errors = e.response.data.error_data.errors;
					if (errors.email?.[0]) {
						const emailError = errors.email[0];

						if (emailError == 'The email has already been taken') {
							error = 'EMAIL_ALREADY_TAKEN';
						}
					}

					if (errors.person_id?.[0]) {
						const idError = errors.person_id[0];

						if (idError == 'The person id has already been taken') {
							error = 'ID_ALREADY_TAKEN';
						}
					}
				}
			} */

			throw e;
		}
	}
}
