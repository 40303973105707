/**
 * Makes only the first letter of the given string uppercase
 *
 * @param str String to convert
 * @returns The string with only the first letter as uppercase
 */
export const toFirstUppercase = (str: string) => {
	const lower = str.toLowerCase();
	const str2 = lower.charAt(0).toUpperCase() + lower.slice(1);

	return str2;
};

/**
 * Splits a PostgreSQL timestamp value in date and time
 *
 * @param tms Timestamp to get the date from
 * @returns An object with the date and time separated
 */
export const getDateFromTimestamp = (tms: string) => {
	try {
		const datetime = new Date(tms);

		const date = `${datetime.getFullYear()}-${startWithZeros(
			datetime.getMonth() + 1,
			2
		)}-${startWithZeros(datetime.getDate(), 2)}`;
		const time = `${startWithZeros(datetime.getHours(), 2)}:${startWithZeros(
			datetime.getMinutes(),
			2
		)}:${startWithZeros(datetime.getSeconds(), 2)}`;

		return {
			date,
			time,
		};
	} catch (e) {
		console.warn('Error converting timestamp');

		return {
			date: '?',
			time: '?',
		};
	}
};

/**
 * Takes a string separated by underscore lines and returns the same string
 * separated by spaces
 *
 * @param str String to split
 * @returns New string
 */
export const splitUnderscore = (str: string) => {
	if (!str) {
		return '';
	}
	
	const newStrParts = str.split('_');
	const newStr = newStrParts.join(' ');

	return newStr;
};

/**
 * Adds the indicated amount of zeros at the start of a string
 *
 * @param str String to modify
 * @param amount Amount of digits to add
 * @returns New string
 */
export const startWithZeros = (str: string | number, amount: number) => {
	return String(str).padStart(amount, '0');
};

export const getMonthName = (num: number, short?: boolean) => {
	const month = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const monthShort = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];

	const monthArray = short ? monthShort : month;

	const name = monthArray[num - 1];
	
	return name;
};

export const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Pad the month, day, hours, minutes, and seconds with leading zeros if they are less than 10
    const paddedMonth = month < 10 ? '0' + month : month;
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedHours = hours < 10 ? '0' + hours : hours;
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const paddedSeconds = seconds < 10 ? '0' + seconds : seconds;

    // Concatenate parts into a Y-m-d H:i:s format
    return `${year}-${paddedMonth}-${paddedDay} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};