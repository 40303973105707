import { useEffect, useState } from 'react';


const INPUT_STATUS = ['NORMAL', 'ERROR'] as const;

export type InputStatus = (typeof INPUT_STATUS)[number];

export type FormInputProp<T> = {
	get: T;
	set: React.Dispatch<React.SetStateAction<T>>;
};

const FormInputPropKeys = [
	'value',
	'originalValue',
	'hint',
	'disabled',
	'status'
] as const;

type FormInputPropsKey = typeof FormInputPropKeys[number];

export type FormInputProps<T extends string> = {
	getValue: () => T;
	setValue: React.Dispatch<React.SetStateAction<T>>;
	getOriginalValue: () => T;
	setOriginalValue: React.Dispatch<React.SetStateAction<T>>;
	getHint: () => string;
	setHint: React.Dispatch<React.SetStateAction<string>>;
	getDisabled: () => boolean;
	setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	getStatus: () => InputStatus;
	setStatus: React.Dispatch<React.SetStateAction<InputStatus>>
};

export const useFormInput = <Type extends string>(
	initialValue?: string,
	initialStatus?: InputStatus,
	initialDisabled?: boolean
) => {
	const [value, setValue] = useState<Type>(initialValue as Type ?? '');
	const [originalValue, setOriginalValue] = useState(value);
	const [status, setStatus] = useState<InputStatus>(
		initialStatus ? initialStatus : 'NORMAL'
	);
	const [hint, setHint] = useState('');
	const [disabled, setDisabled] = useState(initialDisabled ? initialDisabled : false);

	useEffect(() => {
		setValue(originalValue);
	}, [originalValue]);
	
	const props: FormInputProps<typeof value> = {
		getValue: () => value,
		setValue: setValue,
		getOriginalValue: () => originalValue,
		setOriginalValue: setOriginalValue,
		getHint: () => hint,
		setHint: setHint,
		getDisabled: () => disabled,
		setDisabled: setDisabled,
		getStatus: () => status,
		setStatus: setStatus
	};

	return props;
};
