import './AuthenticationModal.css';
import { useTranslation } from 'react-i18next';
import { CommonMainModal, MainModal } from '../MainModal';
import { MainInput } from '../../Inputs/MainInput/MainInput';
import { useFormInput } from '../../../Hooks/useFormInput';
import { MainButton } from '../../Buttons/MainButton/MainButton';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { IDType, IDTypes, User } from '../../../Types/models.types';
import { MainSelect, SelectOption } from '../../Inputs/MainSelect/MainSelect';
import UserController from '../../../Controllers/User.controller';
import { wrongInputs } from '../../../Utils/forms.utils';
import { useNotification } from '../../../Hooks/useNotification';
import { handleComponentError } from '../../../Utils/components.utils';
import { ComponentError } from '../../../errors';
import UserContext from '../../../Context/UserContext';

type SignInModalProps = CommonMainModal;

type AuthModal = 'signin' | 'signup' | 'recovery';

export const AuthenticationModal: React.FC<SignInModalProps> = ({
    modalState
}) => {
    const [visible, setVisible] = modalState;

    const [currentModal, setCurrentModal] = useState<AuthModal>('signin');

    let content = (<></>);


    useEffect(() => {
        if (!visible) {
            setCurrentModal('signin');
        }
    }, [visible]);

    switch (currentModal) {
        case 'signin':
            content = <SignInModal
                currentModalState={[currentModal, setCurrentModal]}
                visibleState={[visible, setVisible]}
            />;
            break;
        case 'signup':
            content = <SignupModal
                currentModalState={[currentModal, setCurrentModal]}
                visibleState={[visible, setVisible]}
            />;
            break;
    }

    return (
        <MainModal
            modalState={modalState}
        >
            {content}
        </MainModal>
    );
};

type CommonAuthenticationModalProps = {
    currentModalState: [AuthModal, React.Dispatch<React.SetStateAction<AuthModal>>]
    visibleState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
};
const SignInModal: React.FC<CommonAuthenticationModalProps> = ({
    currentModalState,
    visibleState
}) => {
    const { t } = useTranslation();

    const userContext = useContext(UserContext);

    const userIdInput = useFormInput();
    const passwordInput = useFormInput();

    const [currentModal, setCurrentModal] = currentModalState;
    const [visible, setVisible] = visibleState;

    const notification = useNotification();

    const userController = new UserController();

    const onSignIn = async () => {
        try {
            const allFields = [userIdInput, passwordInput];

            if (wrongInputs(allFields)) {
                return;
            }

            const result = await userController.login(userIdInput.getValue(), passwordInput.getValue());

            if (!result) {
                throw new ComponentError(t('user.error.errorSigningIn'), 'ERROR');
            }

            userContext?.setUserData(result);

            notification.ok(t('user.signedInSuccessfully'));

            setVisible(false);
        } catch (e) {
            return handleComponentError(e, notification, t, 'user.error.errorSigningIn');
        }
    };

    return (
        <div className="sign-modal sign-in-modal">
            <h2 className="title">
                {t('t.signIn')}
            </h2>
            <div className="form">
                <div id="inputs">
                    <MainInput
                        props={userIdInput}
                        description={t('user.userId')}
                        placeholder={'Email/ID'}
                        required
                    />
                    <div id="password">
                        <MainInput
                            props={passwordInput}
                            description={t('user.password')}
                            placeholder={'********'}
                            type='password'
                            required
                        />
                        {/* <MainButton
                            id='recovery'
                            onClick={() => setCurrentModal('recovery')}
                        >
                            {t('t.forgotYourPassword')}
                        </MainButton> */}
                    </div>
                </div>
                <div id="actions">
                    <MainButton
                        classList={['no-shadow', 'main']}
                        onClick={onSignIn}
                    >
                        {t('t.signIn')}
                    </MainButton>
                    <div id="signup">
                        <span>
                            {t('user.notAMember')}
                        </span>
                        <MainButton
                            classList={['no-shadow', 'no-border', 'fit-content']}
                            onClick={() => setCurrentModal('signup')}
                        >
                            {t('t.signUp')}
                        </MainButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SignupModal: React.FC<CommonAuthenticationModalProps> = ({
    currentModalState,
    visibleState
}) => {
    const { t } = useTranslation();

    const notification = useNotification();

    const [currentModal, setCurrentModal] = currentModalState;
    const [visible, setVisible] = visibleState;

    const userNameInput = useFormInput();
    const userLastNameInput = useFormInput();

    const userIdInput = useFormInput();
    const emailInput = useFormInput();
    const passwordInput = useFormInput();
    const confirmPasswordInput = useFormInput();

    const idTypeInput = useFormInput<IDType>(IDTypes.CC);
    const idTypeOptions: SelectOption[] = Object.keys(IDTypes).map((key) => {
        const idType = key as keyof typeof IDTypes;

        return {
            label: t(`user.idType.${idType}`),
            value: IDTypes[idType]
        };
    });

    const userController = new UserController();

    const onSignUp = async () => {
        try {
            const allInputs = [userIdInput, emailInput, passwordInput, confirmPasswordInput, idTypeInput, userNameInput, userLastNameInput];

            if (wrongInputs(allInputs)) {
                return;
            }

            const newUser: Partial<User> = {
                idType: idTypeInput.getValue(),
                personId: userIdInput.getValue(),
                name: userNameInput.getValue(),
                lastName: userLastNameInput.getValue(),
                email: emailInput.getValue(),
                password: passwordInput.getValue(),
            };

            await userController.saveObject(newUser, 'create');

            notification.ok(t('user.signedUpSuccessfullyNowPleaseSignIn'));

            setCurrentModal('signin');
        } catch (e: any) {
            let error = '';

            console.log(e);
            const errors = e.response?.data?.error_data?.errors;

            if (errors) {
                if (errors.email) {
                    const emailError = errors.email[0];

                    console.log(emailError);

                    if (emailError == 'The email has already been taken.') {
                        error = 'EMAIL_ALREADY_TAKEN';
                    }
                }

                if (errors.person_id) {
                    const idError = errors.person_id[0];

                    console.log(idError);

                    if (idError == 'The person id has already been taken.') {
                        error = 'PERSON_ID_ALREADY_TAKEN';
                    }
                }
            }

            return handleComponentError(error, notification, t, t('user.error.errorSaving'));
        }
    };

    const onChangePasswords = (
        event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
    ) => {
        const confirmValue = confirmPasswordInput.getValue();
        const passwordValue = passwordInput.getValue();

        const targetValue = event.currentTarget.value;

        if (targetValue !== passwordValue && targetValue !== confirmValue) {
            confirmPasswordInput.setStatus('ERROR');
            passwordInput.setStatus('ERROR');

            confirmPasswordInput.setHint(t('user.error.passwordsDontMatch'));
            passwordInput.setHint(t('user.error.passwordsDontMatch'));
            return;
        }

        confirmPasswordInput.setStatus('NORMAL');
        passwordInput.setStatus('NORMAL');
    };

    return (
        <div className="sign-modal sign-up-modal">
            <h2 className="title">
                {t('t.signUp')}
            </h2>
            <div className="form">
                <div id="inputs">
                    <div id="names">
                        <MainInput
                            props={userNameInput}
                            description={t('user.name')}
                            placeholder={t('user.name')}
                            required
                        />
                        <MainInput
                            props={userLastNameInput}
                            description={t('user.lastName_other')}
                            placeholder={t('user.lastName_other')}
                            required
                        />
                    </div>
                    <MainInput
                        props={emailInput}
                        description={t('user.email')}
                        placeholder={'Email'}
                        required
                        isEmail
                    />
                    <div id="user_id">
                        <MainSelect
                            options={idTypeOptions}
                            props={idTypeInput}
                            description={t('user.idType.idType')}
                            filterOptions={false}
                            required
                        />
                        <MainInput
                            props={userIdInput}
                            description={t('user.userId')}
                            placeholder={'ID'}
                            required
                        />
                    </div>
                    <MainInput
                        props={passwordInput}
                        description={t('user.password')}
                        placeholder={'********'}
                        type='password'
                        moreOnChange={[onChangePasswords]}
                        required
                    />
                    <MainInput
                        props={confirmPasswordInput}
                        description={t('user.confirmPassword')}
                        placeholder={'********'}
                        type='password'
                        moreOnChange={[onChangePasswords]}
                        required
                    />
                </div>
                <div id="actions">
                    <MainButton
                        classList={['no-shadow', 'main']}
                        onClick={onSignUp}
                    >
                        {t('t.signUp')}
                    </MainButton>
                    <div id="signin">
                        <span>
                            {t('user.alreadyAMember')}
                        </span>
                        <MainButton
                            classList={['no-shadow', 'no-border', 'fit-content']}
                            onClick={() => setCurrentModal('signin')}
                        >
                            {t('t.signIn')}
                        </MainButton>
                    </div>
                </div>
            </div>
        </div>
    );
};