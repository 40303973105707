import React, { useEffect, useRef, useState } from 'react';
import './MainSelect.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faCheck, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MainButton } from '../../Buttons/MainButton/MainButton';
import { FormInputProps } from '../../../Hooks/useFormInput';
import { useClickOutside } from '../../../Hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

export type SelectOption<T = string> = {
    value: T;
    label: string;
    disabled?: boolean;
};

export type MainSelectProps = {
    placeholder?: string
    description?: string,
    editable?: boolean,
    disabled?: boolean,
    icon?: IconDefinition,
    stopPropagation?: boolean,
    required?: boolean,
    props: FormInputProps<any>,
    options: SelectOption[];
    onSave?: (event: React.MouseEvent<HTMLButtonElement>) => any | Promise<any>;
    moreOnSelect?: (value: string) => any;
    filterOptions?: boolean;
    name?: string
    inputAutocomplete?: boolean
};

export const MainSelect: React.FC<MainSelectProps> = ({
    placeholder = '',
    description = '',
    editable = false,
    disabled = false,
    icon = null,
    stopPropagation = false,
    required = false,
    props,
    onSave = null,
    moreOnSelect = undefined,
    options,
    filterOptions = true,
    name = 'main-select',
    inputAutocomplete = false
}) => {
    const { t } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(disabled);

    const [search, setSearch] = useState('');
    const [extended, setExtended] = useState(false);

    const {getValue, setValue, getOriginalValue, setOriginalValue, getHint, setHint, getStatus, setStatus} = props;

    const ref = useRef<HTMLDivElement>(null);
    const inputContainerRef = useRef<HTMLDivElement>(null);

    const curOption = options.find(option => option.value.toLowerCase() == `${getValue()}`.toLowerCase());
    icon = icon ? icon : faCaretDown;

    useClickOutside(ref, () => {
        closeExtended();
    }, [getValue()]);

    useEffect(() => {
        setOriginalValue(getValue());
    }, []);

    useEffect(() => {
        setSearch(curOption?.label ? curOption.label : '');
    }, [curOption]);

    const closeExtended = () => {
        setExtended(false);

        setSearch(curOption ? curOption.label : '');
    };

    const checkRequired = (value: string) => {
        if (!required || value) {
            setStatus('NORMAL');
            return;
        }

        setHint(t('t.thisFieldIsRequired'));

        setStatus('ERROR');
        return;
    };

    const handleOnClickToggleEdit = (
        event: React.FormEvent<HTMLButtonElement>
    ) => {
        if (stopPropagation) {
            event.stopPropagation();
        }

        const newStatus = !isEditing;

        if (!newStatus) {
            setValue(getOriginalValue());

            const originalSearch = options.find(option => option.value.toLowerCase() == `${getOriginalValue()}`.toLowerCase());

            setSearch(originalSearch?.label ? originalSearch.label : '');
            setExtended(false);
        }

        setIsEditing(newStatus);
        setIsDisabled(!newStatus);
    };

    const handleOnClickSave = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (stopPropagation) {
            event.stopPropagation();
        }

        if (onSave) {
            const result = await onSave(event);

            if (result === false) return;
        }

        checkRequired(getValue());

        if (getStatus() == 'ERROR') return;

        setIsEditing(false);
        setIsDisabled(true);
        setOriginalValue(getValue());

        return;
    };

    const handleOnChangeInput = (
        event: React.FormEvent<HTMLInputElement>
    ) => {
        setSearch(event.currentTarget.value);
    };

    const showOptions = options.filter(option => {
        const label = option.label.toLowerCase();

        if (!filterOptions) return option;

        if (label.includes(search.toLowerCase())) return option;
    });

    const handleSelectOption = (event: React.MouseEvent<HTMLSpanElement>) => {
        const target = event.currentTarget;

        const newValue = target.dataset.value;
        const newLabel = target.dataset.label;

        if (!newValue || !newLabel) return;

        checkRequired(newValue);

        setValue(newValue);
        setSearch(newLabel);

        setExtended(false);

        if (moreOnSelect) {
            moreOnSelect(newValue);
        }

        return;
    };

    const handleKeyEscape = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key == 'Escape') {
            closeExtended();
        }
    };

    return (
        <div ref={ref} className='main-select'>
            {description != '' && (
                <span className='description' title={description}>{description}</span>
            )}
            <div ref={inputContainerRef} className='input-container'>
                <input
                    type="text"
                    value={search}
                    onFocus={() => setExtended(true)}
                    onChange={handleOnChangeInput}
                    onKeyDown={handleKeyEscape}
                    disabled={isDisabled || !(options.length > 0)}
                    placeholder={placeholder}
                    name={name}
                    autoComplete={inputAutocomplete ? 'on' : 'off'}
                />
                {editable && !isEditing && (
                    <div className='input-buttons'>
                        <MainButton onClick={handleOnClickToggleEdit} >
                            <FontAwesomeIcon icon={faPencil} />
                        </MainButton>
                    </div>
                )}
                {editable && isEditing && (
                    <div className='input-buttons'>
                        <MainButton onClick={handleOnClickSave} classList={['check']} >
                            <FontAwesomeIcon icon={faCheck} />
                        </MainButton>
                        <MainButton onClick={handleOnClickToggleEdit} classList={['red']} >
                            <FontAwesomeIcon icon={faTimes} />
                        </MainButton>
                    </div>
                )}
                {icon && (
                    <MainButton
                        onClick={() => {
                            if (extended) {
                                closeExtended();
                            } else {
                                setExtended(true);
                            }
                        }}
                        id="dropdown"
                        classList={['no-border', 'no-fill', 'no-shadow', 'full-height']}
                        disabled={isDisabled || !(options.length > 0)}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </MainButton>
                )}
            </div>
            {extended && (
                <div className='search-list scroll'>
                    {showOptions.length <= 0 && (
                        <span className='select-item disabled empty'>
                            {t('t.noResults')}
                        </span>
                    )}
                    {showOptions.map((option, index) => {
                        return (
                            <span
                                className={`select-item ${getValue() == option.value ? 'selected' : ''} ${option.disabled ? 'disabled' : ''}`}
                                key={index}
                                data-value={option.value}
                                data-label={option.label}
                                onClick={!option.disabled ? handleSelectOption : undefined}
                            >
                                {option.label}
                            </span>
                        );
                    })}
                </div>
            )}
        </div>
    );
};