import Cookies from 'js-cookie';
import { getUserCookie } from '../Context/UserContext';

export const TOKEN_COOKIE_KEY = 'civ-token';

export const getCookie = (name: string) => {
    const cookie = Cookies.get(name);

    if (!cookie) {
        return null;
    }

    return cookie;
};

export const setCookie = (name: string, value: any) => {
    Cookies.set(name, JSON.stringify(value));
};

export const removeCookie = (name: string) => {
    Cookies.remove(name);
};

export const getTokenCookie = () => {
    const cookie = getUserCookie();

    const token = cookie?.token;
    
    return token ? token : null;    
};