import { createContext } from 'react';
import Cookies from 'js-cookie';
import { getCookie, removeCookie, setCookie } from '../Utils/cookies.utils';

export type UserData = {
    name: string,
    lastName: string;
    email: string;
    id: number; 
    uuid: string;
    token: string
};

export type UserContextT = {
    userData: UserData | null;
    setUserData: (userData: UserData | null) => void;
};

export default createContext<UserContextT | null>(null);

export const USER_DATA_COOKIE = 'civ-user';

export const getUserCookie = () => {
    const cookie = getCookie(USER_DATA_COOKIE);

    return cookie ? JSON.parse(cookie) as UserData : null;
};

export const setUserCookie = (userData: UserData) => {
    setCookie(USER_DATA_COOKIE, userData);
};

export const removeUserCookie = () => {
    removeCookie(USER_DATA_COOKIE);
};