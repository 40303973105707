import './ReportModal.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CommonMainModal, MainModal } from '../MainModal';
import { useTranslation } from 'react-i18next';
import { FormInputProps, useFormInput } from '../../../Hooks/useFormInput';
import { MainInput } from '../../Inputs/MainInput/MainInput';
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { PLAZA_DE_BOLIVAR_POS } from '../../../Types/components.types';
import { MainButton } from '../../Buttons/MainButton/MainButton';
import { MainSelect, SelectOption } from '../../Inputs/MainSelect/MainSelect';
import { CivReport, NoiseReport, Report, ReportTypeT, ReportTypesT } from '../../../Types/models.types';
import { wrongInputs } from '../../../Utils/forms.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { useNotification } from '../../../Hooks/useNotification';
import { handleComponentError } from '../../../Utils/components.utils';
import UserContext from '../../../Context/UserContext';
import { ComponentError } from '../../../errors';
import CommonReportController, { SaveCompleteReport } from '../../../Controllers/Reports/CommonReport.controller';
import NoiseReportController from '../../../Controllers/Reports/NoiseReport.controller';
import { formatDate } from '../../../Utils/strings.utils';
import { NoiseReportPage } from './NoiseReportPage';

type PositionState = {
    latitude: number | null;
    longitude: number | null;
    error: string | null;
};

const ReportPages = [
    'form',
    'noise'
] as const;

type ReportPageType = typeof ReportPages[number];

type ReportModalProps = CommonMainModal;
export const ReportModal: React.FC<ReportModalProps> = ({
    modalState
}) => {
    const { t } = useTranslation();

    const notification = useNotification();

    const userContext = useContext(UserContext);

    const [visible, setVisible] = modalState;

    const [page, setPage] = useState<ReportPageType>('form');

    const titleInput = useFormInput();
    const descriptionInput = useFormInput();

    const reportTypeInput = useFormInput<ReportTypeT>('noise');
    const reportTypeOptions: SelectOption[] = Object.keys(ReportTypesT).map((type) => {
        const reportType = type as keyof typeof ReportTypesT;

        const option: SelectOption = {
            label: t(`report.reportType.${reportType}`),
            value: reportType
        };

        return option;
    });

    const [position, setPosition] = useState<PositionState>({
        latitude: null,
        longitude: null,
        error: null
    });
    const [userPosition, setUserPosition] = useState<PositionState>({
        latitude: null,
        longitude: null,
        error: null
    });

    useEffect(() => {
        if (!navigator.geolocation) {
            setUserPosition(prev => ({
                ...prev,
                error: 'Geolocation is not supported by your browser'
            }));
        } else {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        }
    }, []);

    useEffect(() => {
        setPosition(userPosition);
    }, [userPosition]);

    const handleSuccess = (pos: GeolocationPosition) => {
        setUserPosition({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            error: null
        });
    };

    const handleError = (error: GeolocationPositionError) => {
        setUserPosition(prev => ({
            ...prev,
            error: error.message
        }));
    };

    const saveReport = async <T extends CivReport>(
        partialReport: Partial<Report>,
        partialReportType: Partial<CivReport>,
        controller: CommonReportController<T>
    ) => {
        try {
            notification.ok(t('report.thanksForYourReport'));

            titleInput.setValue('');
            descriptionInput.setValue('');

            setPage('form');

            setVisible(false);
        } catch (e) {
            return handleComponentError(e, notification, t, t('report.error.errorSavingReport'));
        }
    };

    let content = (<></>);

    const newReport: Partial<Report> = {
        title: titleInput.getValue(),
        description: descriptionInput.getValue(),
        latitude: position.latitude ?? 0,
        longitude: position.longitude ?? 0
    };

    switch (page) {
        case 'form': {
            content = (
                <FormPage
                    pageState={[page, setPage]}
                    descriptionInput={descriptionInput}
                    titleInput={titleInput}
                    reportTypeInput={reportTypeInput}
                    reportTypeOptions={reportTypeOptions}
                    userPositionStateState={[userPosition, setUserPosition]}
                    positionStateState={[position, setPosition]}
                />
            );

            break;
        }
        case 'noise': {
            content = (
                <NoiseReportPage
                    pageState={[page, setPage]}
                    partialReport={newReport}
                    onSaveReport={saveReport}
                />
            );

            break;
        }
    }

    return (
        <MainModal
            modalState={modalState}
        >
            <div className="report-modal">
                <h2 className="title">
                    {t('report.yourReport')}
                </h2>
                <div className="report-page">
                    {content}
                </div>
            </div>
        </MainModal>
    );
};

export type ReportPage = {
    pageState: [ReportPageType, React.Dispatch<React.SetStateAction<ReportPageType>>];
};

export type ReportTypePage = ReportPage & {
    partialReport: Partial<Report>
    onSaveReport: <T extends CivReport>(partialReport: Partial<Report>, partialReportType: Partial<CivReport>, controller: CommonReportController<T>) => Promise<boolean | undefined>
};

type FormPageProps = ReportPage & {
    titleInput: FormInputProps<string>;
    descriptionInput: FormInputProps<string>;
    reportTypeInput: FormInputProps<ReportTypeT>;
    reportTypeOptions: SelectOption[];
    userPositionStateState: [PositionState, React.Dispatch<React.SetStateAction<PositionState>>];
    positionStateState: [PositionState, React.Dispatch<React.SetStateAction<PositionState>>];
};
const FormPage: React.FC<FormPageProps> = ({
    pageState,
    titleInput,
    descriptionInput,
    reportTypeInput,
    reportTypeOptions,
    userPositionStateState,
    positionStateState
}) => {
    const { t } = useTranslation();

    const [page, setPage] = pageState;

    const [userPosition, setUserPosition] = userPositionStateState;
    const [position, setPosition] = positionStateState;

    const onClickNext = () => {
        if (wrongInputs([titleInput, descriptionInput, reportTypeInput])) {
            return;
        }

        setPage(reportTypeInput.getValue());
    };

    return (
        <div id="form-page">
            <MainSelect
                options={reportTypeOptions}
                props={reportTypeInput}
                description={t('report.reportType.reportType')}
            />
            <MainInput
                props={titleInput}
                description={t('t.title')}
            />
            <MainInput
                props={descriptionInput}
                description={t('t.description')}
                textarea
            />
            <div id="form-map">
                <span>
                    {t('t.location')}
                </span>
                <ModalMap
                    userPositionStateState={[userPosition, setUserPosition]}
                    positionStateState={[position, setPosition]}
                />
            </div>
            <MainButton
                classList={['main']}
                onClick={onClickNext}
            >
                {t('t.next')}
            </MainButton>
        </div>
    );
};

type ModalMapProps = {
    userPositionStateState: [PositionState, React.Dispatch<React.SetStateAction<PositionState>>];
    positionStateState: [PositionState, React.Dispatch<React.SetStateAction<PositionState>>];
};

const ModalMap: React.FC<ModalMapProps> = ({
    userPositionStateState,
    positionStateState
}) => {
    const { t } = useTranslation();

    const [userPositionState, setUserPositionState] = userPositionStateState;
    const [positionState, setPositionState] = positionStateState;

    const userLat = userPositionState.latitude ?? PLAZA_DE_BOLIVAR_POS.lat;
    const userLong = userPositionState.longitude ?? PLAZA_DE_BOLIVAR_POS.long;

    const userLatRef = useRef(userLat);
    const userLongRef = useRef(userLong);

    const lat = positionState.latitude ?? PLAZA_DE_BOLIVAR_POS.lat;
    const long = positionState.longitude ?? PLAZA_DE_BOLIVAR_POS.long;

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                setPositionState({ latitude: e.latlng.lat, longitude: e.latlng.lng, error: null });
            }
        });

        return (
            <Marker
                position={[lat, long]}
            ></Marker>
        );
    };

    const UserPositionHandler = () => {
        const map = useMap();

        if (userLatRef.current !== userLat || userLongRef.current !== userLong) {
            map.setView([userLat, userLong], 18);

            userLatRef.current = userLat;
            userLongRef.current = userLong;
        }
        return null;
    };

    return (
        <div id="modal-map" className="rect-container base-shadow">
            <MapContainer
                center={[userLat, userLong]}
                zoom={18}
                scrollWheelZoom={false}
                
            >
                <UserPositionHandler />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
            </MapContainer>
        </div>
    );
};