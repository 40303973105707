import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import APIController from './CommonController.controller';
import { Model } from '../Types/models.types';
import { i18n } from 'i18next';
import { ComponentError } from '../errors';
import { getTokenCookie } from '../Utils/cookies.utils';
import { QueryFilter } from '../Types/api.types';

export default class ModelController<T extends Model> extends APIController {
	objectName: string;

	constructor(objectName: string) {
		super();

		this.objectName = objectName;
	}

	public async getObjectList(filters?: QueryFilter<T>[]): Promise<T[]> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			config.params = {};

			const response = await axios.get(
				`${this.api.url}/${this.objectName}?query_filters=${JSON.stringify(filters)}`,
				config
			);		

			return response.data.data;
		} catch (e) {
			console.error(`Error getting objects ${this.objectName}:` + e);
			throw e;
		}
	}

	public getFirst = async (filters?: QueryFilter<T>[]) => {
		try {
			const list = await this.getObjectList(filters);

			if (list[0]) {
				return list[0];
			}

			return undefined;
		} catch (e) {
			console.error(`Error getting first object ${this.objectName}:` + e);
			throw e;
		}
	};

	public async getObject(id: number | string): Promise<T | undefined> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();
			
			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			const response = await axios.get(
				`${this.api.url}/${this.objectName}/${id}`,
				config
			);

			return response.data.data;
		} catch (e) {
			console.error(
				`Error getting object ${this.objectName} with ID ${id}:` + e
			);
			throw e;
		}
	}

	public async saveObject(
		newObject: Partial<T>,
		action: 'create' | 'update'
	): Promise<T> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			let response = undefined;

			if (action == 'create') {
				response = await axios.post(
					`${this.api.url}/${this.objectName}`,
					{ ...newObject },
					config
				);
			} else {
				if (!newObject.id) {
					throw new Error('No object ID');
				}

				response = await axios.put(
					`${this.api.url}/${this.objectName}/${newObject.id}`,
					{ ...newObject },
					config
				);
			}

			console.log(response);

			if (!response.data) {
				throw new Error(JSON.stringify(response));
			}

			return response.data;
		} catch (e: any) {
			let error = e;
			console.error('Error:' + e);

			if (e instanceof AxiosError) {
				if (e.response?.data?.error_data?.errors) {
					const errors = e.response.data.error_data.errors;
					if (errors.email?.[0]) {
						const emailError = errors.email[0];

						if (emailError == 'The email has already been taken') {
							error = 'EMAIL_ALREADY_TAKEN';
						}
					}

					if (errors.person_id?.[0]) {
						const idError = errors.person_id[0];

						if (idError == 'The person id has already been taken') {
							error = 'ID_ALREADY_TAKEN';
						}
					}
				}
			}

			throw error;
		}
	}

	public async deleteObject(id: number) {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			const response = await axios.delete(
				`${this.api.url}/${this.objectName}/${id}`,
				config
			);

			return response.data;
		} catch (e) {
			console.error(
				`Error deleting object ${this.objectName} with ID ${id}:` + e
			);
			throw e;
		}
	}

	/* public uploadIcon = async (id: number, icon: File) => {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				[this.api.accessTokenName]: token,
			};

			const formData = new FormData();

			formData.append('icon', icon);

			const response = await axios.post(
				`${this.api.url}/${this.objectName}/icon/${id}`,
				formData,
				config
			);

			return response;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}; */

	/* public generatePdf = async (
		token: string,
		i18n: i18n,
		startDate?: string,
		endDate?: string
	) => {
		try {
			const lang = i18n.language;

			const config: AxiosRequestConfig = {};

			config.headers = {
				[this.api.accessTokenName]: token,
			};

			const response = await axios.post(
				`${this.api.url}/${this.objectName}/pdf`,
				{ lang, startDate, endDate },
				config
			);

			console.log(response);

			return response.data as { fileName: string };
		} catch (e) {
			console.error(e);
			throw e;
		}
	}; */
}
