import { useContext, useState } from 'react';
import './MapStatistics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faChartBar, faClock, faTable, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MainButton } from '../Buttons/MainButton/MainButton';
import { useTranslation } from 'react-i18next';
import { DashboardSchedule, ScheduleChart } from '../../Pages/Dashboard/DashboardSchedule';
import DashboardContext from '../../Context/DashboardContext';
import UserContext from '../../Context/UserContext';
import { useNotification } from '../../Hooks/useNotification';
import { MainInput } from '../Inputs/MainInput/MainInput';
import { useFormInput } from '../../Hooks/useFormInput';

export const MapStatistics = () => {
    const { t } = useTranslation();

    const userContext = useContext(UserContext);

    const userData = userContext?.userData ?? null;

    const notification = useNotification();

    const { reportList } = useContext(DashboardContext);

    const [extended, setExtended] = useState(true);
    const [changing, setChanging] = useState(false);

    const [view, setView] = useState<'table' | 'chart'>('chart');

    const onClickExtend = (newStatus: boolean) => {
        setExtended(newStatus);
        setChanging(true);

        setTimeout(() => {
            setChanging(false);
        }, 300);
    };

    return (
        <div className={`map-statistics ${extended ? 'extended' : ''}`}>
            {changing && (
                <></>
            )}
            {!changing && !extended && (
                <MainButton
                    classList={['full-width', 'full-height', 'gray']}
                    onClick={() => onClickExtend(true)}
                >
                    <FontAwesomeIcon icon={faClock} />
                </MainButton>
            )}
            {!changing && extended && (
                <div className='stats'>
                    <div className="stats-top">
                        <span>
                            {t('report.reportSchedule')}
                        </span>
                        <MainButton
                            onClick={() => onClickExtend(false)}
                            classList={['no-shadow', 'no-border']}
                        >
                            <FontAwesomeIcon icon={faCaretDown} />
                        </MainButton>
                        <MainButton
                            classList={[(view == 'chart' ? 'main' : 'gray')]}
                            onClick={() => setView('chart')}
                        >
                            <FontAwesomeIcon icon={faChartBar} />
                        </MainButton>
                        <MainButton
                            classList={[(view == 'table' ? 'main' : 'gray')]}
                            onClick={() => setView('table')}
                        >
                            <FontAwesomeIcon icon={faTable} />
                        </MainButton>
                    </div>
                    <div className="stats-content">
                        <DashboardSchedule
                            reportList={reportList}
                            show={view}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};