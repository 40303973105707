import { TFunction } from 'i18next';
import { UseNotification } from '../Hooks/useNotification';
import { ComponentError } from '../errors';

export const KnownErrors = {
	ECONNREFUSED: 'Connection error',
	EMAIL_ALREADY_TAKEN: 'emailAlreadyTaken',
	PERSON_ID_ALREADY_TAKEN: 'personIdAlreadyTaken',
	USER_NOT_FOUND: 'userNotFound',
	WRONG_PASSWORD: 'wrongPassword',
	UNKNOWN: 'unknownError',
} as const;

export type KnownComponentError = keyof typeof KnownErrors;

/**
 * Shows the sent error, or an unknown error
 *
 * @param error Error to handle
 * @param notification Notification
 * @returns
 */
export const handleComponentError = (
	error: any,
	notification: UseNotification,
	t: TFunction,
	defaultError = 'Unknown error'
) => {
	console.error(error);

	if (error instanceof ComponentError) {
		notification.notify({
			text: error.description,
			type: error.notificationStatus,
		});
		return false;
	}

	for (const key in KnownErrors) {
		const errorKey = key as KnownComponentError;

		if (
			error?.response?.data == errorKey ||
			error?.response?.data?.error == errorKey ||
			error == errorKey
		) {
			notification.notify({
				text: t(`error.${KnownErrors[errorKey]}`),
				type: 'ERROR',
			});
			return false;
		}

		if (error?.response?.data.message == 'jwt expired') {
			notification.notify({ text: 'Session expired', type: 'ERROR' });
			location.reload();
			return false;
		}
	}

	notification.error(defaultError);
	return false;
};
