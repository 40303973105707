import { faker } from '@faker-js/faker';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    ChartData,
    ChartOptions,
} from 'chart.js';
import { Chart, ChartProps } from 'react-chartjs-2';
import { t } from 'i18next';
import { startWithZeros } from '../../Utils/strings.utils';
import { CivReport, CompleteReport } from '../../Types/models.types';
import { ReactNode } from 'react';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

type DashboardScheduleProps = {
    reportList: CompleteReport<CivReport>[],
    show?: 'all' | 'chart' | 'table'
};
export const DashboardSchedule: React.FC<DashboardScheduleProps> = ({
    reportList,
    show = 'all'
}) => {

    console.log(reportList);

    const reportGroups: { [key: number]: CompleteReport<CivReport>[] } = {};

    for (const completeReport of reportList) {
        const { report } = completeReport;

        const date = new Date(report.tms);

        const hour = date.getHours();

        if (!reportGroups[hour]) {
            reportGroups[hour] = [];
        }

        reportGroups[hour].push(completeReport);
    }

    const reportGroupCount = [];

    for (let i = 0; i < 24; i++) {
        const reports = reportGroups[i] ?? undefined;

        if (!reports) {
            reportGroupCount[i] = 0;
            continue;
        }

        reportGroupCount[i] = reports.length;
    }

    const schedulesToPrint: ReactNode[] = [];

    let totalPrinted = 0;

    for (let i = 0; i < reportGroupCount.length; i++) {
        if (totalPrinted >= 5) {
            break;
        }

        const groupCount = reportGroupCount[i];

        if (groupCount == 0) {
            continue;
        }


        const toPrint = (
            <div className="schedule" key={i}>
                <span>
                    {`${startWithZeros(i, 2)}:00 - ${startWithZeros(i + 1, 2)}:00`}
                </span>
                <span>
                    {`${groupCount} ${t('report.report_other')}`}
                </span>
            </div>
        );

        schedulesToPrint.push(toPrint);

        totalPrinted++;
    }

    return (
        <div id="schedule">
            {(show == 'all' || show == 'chart') && (
                <div id="chart">
                    <ScheduleChart
                        reportData={reportGroupCount}
                    />
                </div>
            )}
            {(show == 'all' || show == 'table') && (
                <div id="schedule_list_container">
                    <h3 className="title">
                        {t('report.criticalSchedules')}
                    </h3>
                    {!(schedulesToPrint.length > 0) && (
                        <span>
                            {t('report.noReportsFound')}
                        </span>
                    )}
                    <div id="list">
                        {schedulesToPrint}
                    </div>
                </div>
            )}
        </div>
    );
};

type ScheduleChartProps = {
    reportData: number[],
};
export const ScheduleChart: React.FC<ScheduleChartProps> = ({
    reportData
}) => {
    // Array starting in 1 to 24
    const labels = Array.from({ length: 24 }, (_, i) => `${startWithZeros(i, 2)}:00`);

    const data: ChartData<'bar', number[], string> = {
        labels,
        datasets: [
            {
                type: 'bar' as const,
                label: 'Dataset 2',
                backgroundColor: 'rgb(63, 183, 63)',
                data: reportData,
            },
        ],
    };

    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return <Chart type='bar' data={data} options={chartOptions} />;
};