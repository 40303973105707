import axios, { AxiosRequestConfig } from 'axios';
import { CivReport, CompleteReport, Report } from '../../Types/models.types';
import ModelController from '../ModelController.controller';
import ReportController from './Report.controller';
import { getTokenCookie } from '../../Utils/cookies.utils';
import { QueryFilter } from '../../Types/api.types';

export type CompleteReportFilters<T extends CivReport> = {
	report: QueryFilter<Report>[];
	reportType: QueryFilter<T>[];
};

export type SaveCompleteReport = {
	reportData: Partial<Report>,
	reportTypeData: Partial<CivReport>
};
export default class CommonReportController<T extends CivReport> extends ModelController<T> {
	constructor(objectName: string) {
		super(objectName);
	}

	public async getCompleteObjectList(filters?: CompleteReportFilters<T>): Promise<CompleteReport<T>[]> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			config.params = {};

			const reportFilters = JSON.stringify(filters?.report);
			const reportTypeFilters = JSON.stringify(filters?.reportType);

			const response = await axios.get(
				`${this.api.url}/${this.objectName}/complete?report_filters=${reportFilters}&report_type_filters=${reportTypeFilters}`,
				config
			);

			return response.data.data;
		} catch (e) {
			console.error(`Error getting objects ${this.objectName}:` + e);
			throw e;
		}
	}

	public async getCompleteById(id: number): Promise<T[]> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			config.params = {};

			const response = await axios.get(
				`${this.api.url}/${this.objectName}/complete/${id}`,
				config
			);

			return response.data.data;
		} catch (e) {
			console.error(`Error getting objects ${this.objectName}:` + e);
			throw e;
		}
	}

	public getReport = async (fkReport: number) => {
		try {
			const reportController = new ReportController();

			const report = await reportController.getObject(fkReport);

			console.log(report);

			return report;
		} catch (e) {
			console.error(e);

			throw e;
		}
	};

	public async createReport(
		newObject: Partial<SaveCompleteReport>,
	): Promise<T> {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			let response = undefined;

			response = await axios.post(
				`${this.api.url}/${this.objectName}`,
				{ ...newObject },
				config
			);

			console.log(response);

			if (!response.data) {
				throw new Error(JSON.stringify(response));
			}

			return response.data;
		} catch (e: any) {
			const error = e;
			console.error('Error:' + e);

			/* if (e instanceof AxiosError) {
				if (e.response?.data?.error_data?.errors) {
					const errors = e.response.data.error_data.errors;
					if (errors.email?.[0]) {
						const emailError = errors.email[0];

						if (emailError == 'The email has already been taken') {
							error = 'EMAIL_ALREADY_TAKEN';
						}
					}

					if (errors.person_id?.[0]) {
						const idError = errors.person_id[0];

						if (idError == 'The person id has already been taken') {
							error = 'ID_ALREADY_TAKEN';
						}
					}
				}
			} */

			throw e;
		}
	}
}
