import './MapSidebar.css';
import { faBullhorn, faCaretRight, faClock, faSignOut, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonMapProps } from '../../Pages/Dashboard/DashboardAlt';
import UserContext, { removeUserCookie } from '../../Context/UserContext';
import { AuthenticationModal } from '../Modals/AuthenticationModal/AuthenticationModal';
import { MainButton } from '../Buttons/MainButton/MainButton';
import { MainModal } from '../Modals/MainModal';
import UserController from '../../Controllers/User.controller';
import { useNotification } from '../../Hooks/useNotification';
import { CompleteReport, CivReport, NoiseReport, User } from '../../Types/models.types';
import { handleComponentError } from '../../Utils/components.utils';
import { ReportModal } from '../Modals/ReportModal/ReportModal';
import DashboardContext from '../../Context/DashboardContext';

const MapSidebarMenus = [
    'user',
    'reports',
    'stats'
] as const;

type MapSidebarMenu = typeof MapSidebarMenus[number];

export const MapSidebar = () => {
    const { t } = useTranslation();

    const [sidebarExtended, setSidebarExtended] = useState(false);

    const [activeMenu, setActiveMenu] = useState<MapSidebarMenu>();

    const onClickMenu = (menu: MapSidebarMenu | undefined) => {
        const newActiveMenu = menu == activeMenu ? undefined : menu;

        if (!newActiveMenu) {
            setSidebarExtended(false);

            setTimeout(() => {
                setActiveMenu(newActiveMenu);
            }, 300);
        } else {
            setSidebarExtended(true);

            setActiveMenu(newActiveMenu);
        }

    };

    let content: ReactNode = null;

    switch (activeMenu) {
        case 'user': {
            content = (<UserMenu />);
            break;
        }
        case 'reports': {
            content = (
                <ReportsMenu />
            );
            break;
        }
        case 'stats': {
            break;
        }
        default: {
            break;
        }
    }

    return (
        <div
            className={`map-sidebar base-shadow ${sidebarExtended ? 'extended' : ''}`}
        >
            <div className="menus">
                <SidebarMenuItem
                    icon={faUser}
                    label={t('user.user')}
                    className={(sidebarExtended && activeMenu == 'user') ? 'selected' : ''}
                    onClick={() => onClickMenu('user')}
                />
                <SidebarMenuItem
                    icon={faBullhorn}
                    label={t('report.report_other')}
                    className={(sidebarExtended && activeMenu == 'reports') ? 'selected' : ''}
                    onClick={() => onClickMenu('reports')}
                />
            </div>
            <div className="sidebar-content">
                {content}
            </div>
        </div>
    );
};

type SidebarMenuItemProps = {
    icon: IconDefinition,
    label: string,
    className?: string,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};
const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
    icon,
    label,
    className = '',
    onClick = undefined
}) => {
    return (
        <div
            className={`sidebar-menu-item ${className}`}
            onClick={onClick}
        >
            <FontAwesomeIcon className='icon' icon={icon} />
            <span>
                {label}
            </span>
        </div>
    );
};

/* type ReportsMenuProps = MapSidebarProps; */
const ReportsMenu = () => {
    return (
        <div className="reports-menu">
            <LastReportsContainer />
        </div>
    );
};

/* type LastReportsContainerProps = {
    reportList: CompleteReport<CivReport>[],
}; */
const LastReportsContainer = () => {
    const { t } = useTranslation();

    const notification = useNotification();

    const userContext = useContext(UserContext);

    const { reportList, startDate, endDate } = useContext(DashboardContext);

    console.log(reportList);

    return (
        <>
            <div id="last-reports">
                <div id="counter">
                    <span>
                        {reportList.length}
                    </span>
                    <span>
                        {t('report.reportsBetween', { from: startDate, to: endDate })}
                    </span>
                </div>
                <div className="report-list">
                    {reportList.map((completeReport) => {
                        const { report } = completeReport;

                        switch (report.fkReportType) {
                            case 'noise':
                                return (
                                    <NoiseReportItem key={report.id} completeReport={completeReport} />
                                );
                            default:
                                return null;
                        }
                    })}
                </div>
            </div>
        </>
    );
};

type ReportItemProps<T extends CivReport> = {
    completeReport: CompleteReport<T>;
    className?: string
};
export const NoiseReportItem: React.FC<ReportItemProps<NoiseReport>> = ({
    completeReport,
    className = ''
}) => {
    const { t } = useTranslation();
    const notification = useNotification();

    const { report, reportType: noiseReport, user: reportAuthor } = completeReport;

    const maxLimit = 150;

    const offsetPercent = 3;
    const maxDecibelsPercent = (noiseReport.maxDecibels * 100 / maxLimit) - offsetPercent;
    const minDecibelsPercent = (noiseReport.minDecibels * 100 / maxLimit) - offsetPercent;

    return (
        <div className={`report noise-report ${className}`}>
            <div className="general-info">
                <div className='top'>
                    <div className='report-info'>
                        <span className="report-title">
                            {report.title}
                        </span>
                        <span>
                            {report.tms}
                        </span>
                    </div>
                    <span className="report-description">
                        <i>
                            &quot;{report.description}&quot;
                        </i>
                    </span>
                </div>
                {reportAuthor && (
                    <div className="report-author">
                        <span>
                            {t('report.reportedBy')}
                        </span>
                        <span>
                            {`${reportAuthor.name} ${reportAuthor.lastName}`}
                        </span>
                    </div>
                )}
            </div>
            <div className="report-type-details">
                <div className="report-type">
                    <FontAwesomeIcon icon={faBullhorn} className='icon' />
                    <span>
                        {t(`report.reportType.${report.fkReportType}`)}
                    </span>
                </div>
                <div className='decibels-info'>
                    <span className='min'>
                        Min: {noiseReport.minDecibels} dB
                    </span>
                    <span className='max'>
                        Max: {noiseReport.maxDecibels} dB
                    </span>
                    <span className="duration">
                        <FontAwesomeIcon icon={faClock} />
                        {noiseReport.duration} s
                    </span>
                </div>
                <div className="decibels">
                    <div id="markers">
                        <div
                            id="max-marker"
                            className='marker'
                            style={{ bottom: `${maxDecibelsPercent}%` }}
                        >
                            <span>
                                {noiseReport.maxDecibels} dB
                            </span>
                            <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                        <div
                            id="min-marker"
                            className='marker'
                            style={{ bottom: `${minDecibelsPercent}%` }}
                        >
                            <span>
                                {noiseReport.minDecibels} dB
                            </span>
                            <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                    </div>
                    <div id="meter"></div>
                </div>
            </div>
        </div>
    );
};

const UserMenu = () => {
    const { t } = useTranslation();

    const userContext = useContext(UserContext);
    const userData = userContext?.userData ?? null;

    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const [signOutModalVisible, setSignOutModalVisible] = useState(false);

    const notification = useNotification();

    const userController = new UserController();
    
    const onLogout = async () => {
        try {
            const logOut = await userController.logout();

            if (!logOut) {
                throw new Error('Error logging out');
            }
            
            userContext?.setUserData(null);

            removeUserCookie();
            setSignOutModalVisible(false);
        } catch (e) {
            return handleComponentError(e, notification, t, t('user.error.errorLoggingOut'));
        }
    };

    return (
        <>
            <AuthenticationModal
                modalState={[signInModalVisible, setSignInModalVisible]}
            />
            <MainModal
                modalState={[signOutModalVisible, setSignOutModalVisible]}
            >
                <div className="signout-modal">
                    <h3 className="title">
                        {t('t.signOut')}
                    </h3>
                    <span>
                        {t('t.areYouSureYouWantToSignOut')}
                    </span>
                    <div className="actions">
                        <MainButton
                            onClick={onLogout}
                            classList={['red', 'no-border', 'no-shadow', 'full-width']}
                        >
                            {t('t.yes')}
                        </MainButton>
                        <MainButton
                            onClick={() => setSignOutModalVisible(false)}
                            classList={['no-border', 'no-shadow', 'dark-gray', 'full-width']}
                        >
                            {t('t.no')}
                        </MainButton>
                    </div>
                </div>
            </MainModal>
            <div className={'user-menu'}>
                <div id="top">
                    <div className="user-info">
                        <div id="icon">
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        {userData ? (
                            <div id="info">
                                {`${userData.name} ${userData.lastName}`}
                            </div>
                        ) : (
                            <MainButton
                                classList={['no-shadow', 'dark-gray']}
                                onClick={() => setSignInModalVisible(true)}
                            >
                                {t('t.signIn')}
                            </MainButton>
                        )}
                    </div>
                    {/* {userData && (
                            <SidebarItem
                                icon={faBullhorn}
                                label={t('report.myReports')}
                                to='/my_reports'
                            />
                        )} */}
                </div>
                <div id="bottom">
                    {userData && (
                        <div
                            id="logout"
                            onClick={() => setSignOutModalVisible(true)}
                        >
                            <FontAwesomeIcon icon={faSignOut} />
                            <span>
                                {t('t.signOut')}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};