import { FormInputProps } from '../Hooks/useFormInput';


export const wrongInputs = (inputs: FormInputProps<any>[]) => {
    let wrongInputs = false;

    inputs.forEach((input) => {
        if (input.getValue() == '') {
            wrongInputs = true;
            input.setStatus('ERROR');
            input.setHint('This field is required');
        }
        if (input.getStatus() == 'ERROR') {
            wrongInputs = true;
        }
    });

    return wrongInputs;
};