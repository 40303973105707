import React, { useEffect } from 'react';

export const useClickOutside = (
	ref: React.RefObject<HTMLElement>,
	handle: (event?: MouseEvent) => any,
	dependencies: any[] = [],
	ignoreList: React.RefObject<HTMLElement | null>[] = []
) => {
	useEffect(() => {
		function handleClickOutside(this: Document, event: MouseEvent) {
			const eventTarget = event.target as HTMLElement | null;
			
			for (const ignore of ignoreList) {
				let current = eventTarget as HTMLElement | null;

				while (current) {
					if (current == ignore.current) {
						return;
					}
					current = current.parentElement;
				}

				if (ignore.current == event.currentTarget) {
					return;
				}
			}
						
			if (ref.current && eventTarget && !ref.current.contains(eventTarget)) {
				handle(event);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, ...dependencies]);
};
