import React, { createContext, useEffect, useRef } from 'react';
import { NotificationItem, NotificationPopupProps } from '../Types/components.types';
import { NotificationPopupStatus } from '../Components/Notification/Notification';


export type NotificationContextI = {
	notification: NotificationPopupProps;
};

const defNotify = (props: { type?: NotificationPopupStatus; text: string }) => {
    return;
};

const list: React.MutableRefObject<NotificationItem[]> = {current: []};

const NotificationContext: NotificationContextI = {
	notification: {
		notify: defNotify,
		notificationList: list,
	},
};

export default createContext<typeof NotificationContext>(NotificationContext);
