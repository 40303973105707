import { useContext } from 'react';
import NotificationContext from '../Context/NotificationContext';
import { NotifyProps } from '../Types/components.types';

export type NotificationFunction = (text: string, manual?: boolean) => void;

export type UseNotification = {
	notify: (props: NotifyProps) => void;
	ok: NotificationFunction;
	loading: NotificationFunction;
	warn: NotificationFunction;
	error: NotificationFunction;
};
export const useNotification = (): UseNotification => {
	const { notification } = useContext(NotificationContext);

	const notify = (props: NotifyProps) => {
		if (!notification) {
			console.warn('No notification set');
			return;
		}

		notification.notify(props);
	};

	const ok = (text: string, manual = false) => {
		notify({
			text,
			type: 'NORMAL',
			closeType: manual ? {type: 'manual'} : undefined
		});
	};

	const loading = (text: string, manual = false) => {
		notify({
			text,
			type: 'LOADING',
			closeType: manual ? {type: 'manual'} : undefined
		});
	};


	const warn = (text: string, manual = false) => {
		notify({
			text,
			type: 'WARNING',
			closeType: manual ? {type: 'manual'} : undefined
		});
	};

	const error = (text: string, manual = false) => {
		notify({
			text,
			type: 'ERROR',
			closeType: manual ? {type: 'manual'} : undefined
		});
	};

	const result: UseNotification = {
		notify,
		ok,
		loading,
		warn,
		error
	};
	return result;
};
