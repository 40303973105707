import axios, { AxiosRequestConfig } from 'axios';
import { CivReport, CompleteReport, Report } from '../../Types/models.types';
import ModelController from '../ModelController.controller';
import { getTokenCookie } from '../../Utils/cookies.utils';

export default class ReportController extends ModelController<Report> {
	constructor() {
		const objectName = 'report';

		super(objectName);
	}

	public getUserLastReport = async (
		userId: number
	): Promise<CompleteReport<CivReport> | undefined> => {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			const response = await axios.get(
				`${this.api.url}/${this.objectName}/last/${userId}`,
				config
			);

			return response.data.data;
		} catch (e) {
			console.error(
				`Error getting last report for user with ID ${userId}:` + e
			);
			throw e;
		}
	};
}
