export default class API {

    url: string;

    constructor () {
        console.log(process.env.REACT_APP_API_URL);        
        
        this.url = process.env.REACT_APP_API_URL || 'http://127.0.0.2/api';
    }

}