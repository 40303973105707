import React, { ReactNode, useRef, useState } from 'react';
import '../MainButton.css';
import { ButtonClass } from '../../../Types/components.types';

interface MainButtonProps {
    children?: ReactNode;
    id?: string;
    name?: string;
    className?: string;
    classList?: ButtonClass[];
    title?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<any> | any;
}
const MButton = ({
    children,
    id = undefined,
    name = undefined,
    className = '',
    classList = [],
    title = '',
    style = {},
    disabled = false,
    onClick = undefined
}: MainButtonProps, ref: any) => {
    const buttonRef = ref ? ref : useRef<HTMLButtonElement>(null);

    const handleOnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const button = buttonRef.current;

        if (!button) {
            return;
        }

        button.style.opacity = '50%';
        button.setAttribute('disabled', 'true');

        if (onClick) {
            console.log('action');

            await onClick(event);
        }

        button.style.opacity = '100%';
        button.removeAttribute('disabled');
    };


    return (
        <button
            ref={buttonRef}
            id={id}
            name={name}
            className={`main-button ${className} ${classList.join(' ')}`}
            onClick={handleOnClick}
            title={title}
            disabled={disabled}
            style={style}
        >
            {children}
        </button>
    );
};

export const MainButton = React.forwardRef<HTMLButtonElement, MainButtonProps>(MButton);