import { faMicrophone, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NoiseReportController from '../../../Controllers/Reports/NoiseReport.controller';
import { useNotification } from '../../../Hooks/useNotification';
import { NoiseReport, Report } from '../../../Types/models.types';
import { MainButton } from '../../Buttons/MainButton/MainButton';
import { ReportTypePage } from './ReportModal';
import UserContext from '../../../Context/UserContext';
import { ComponentError } from '../../../errors';
import { formatDate } from '../../../Utils/strings.utils';
import { SaveCompleteReport } from '../../../Controllers/Reports/CommonReport.controller';
import { handleComponentError } from '../../../Utils/components.utils';

const mimeType = 'audio/webm';

type NoiseReportPageProps = ReportTypePage;
export const NoiseReportPage: React.FC<NoiseReportPageProps> = ({
    pageState,
    partialReport,
    onSaveReport
}) => {
    const { t } = useTranslation();

    const notification = useNotification();

    const userContext = useContext(UserContext);

    const [page, setPage] = pageState;

    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState<MediaStream>();

    const MAX_RECORDING_DURATION = 10;

    const [recordingDuration, setRecordingDuration] = useState(0);
    const recordingDurationRef = useRef(recordingDuration);

    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const [recordingStatus, setRecordingStatus] = useState<'recording' | 'inactive'>('inactive');
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [audio, setAudio] = useState<string>();
    const [audioBlob, setAudioBlob] = useState<Blob>();

    const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
    const analyzerRef = useRef<AnalyserNode | null>(null);

    const [currentDBLevel, setCurrentDBLevel] = useState(0);
    const [maxDecibels, setMaxDecibels] = useState<number>();
    const [minDecibels, setMinDecibels] = useState<number>();

    const maxDecibelsRef = useRef(maxDecibels);
    const minDecibelsRef = useRef(minDecibels);

    const noiseReportController = new NoiseReportController();

    useEffect(() => {
        if (!partialReport.title || !partialReport.description) {
            setPage('form');

            setRecordingStatus('inactive');
            setRecordingDuration(0);
            setAudio(undefined);
            setAudioChunks([]);
            setPermission(false);
            setStream(undefined);
            
            return;
        }
    }, [partialReport]);

    useEffect(() => {
        if (recordingStatus == 'inactive') {
            return;
        }

        const recordInterval = setInterval(() => {
            const newDuration = recordingDurationRef.current + 1;
            setRecordingDuration(newDuration);
            recordingDurationRef.current = newDuration;

            if (newDuration >= MAX_RECORDING_DURATION) {
                stopRecording();
            }
        }, 1000);

        return () => {
            clearInterval(recordInterval);
        };
    }, [recordingStatus]);

    useEffect(() => {
        if (!permission || !analyzerRef.current) return;

        if (recordingStatus != 'recording') {
            return;
        }

        const interval = setInterval(() => {
            const dB = Math.round(getDecibels());

            const curMaxDecibels = maxDecibelsRef.current;
            const curMinDecibles = minDecibelsRef.current;

            if (!curMaxDecibels || dB > curMaxDecibels) {
                setMaxDecibels(dB);
                maxDecibelsRef.current = dB;
            }
            if (!curMinDecibles || dB < curMinDecibles) {
                setMinDecibels(dB);
                minDecibelsRef.current = dB;
            }

            setCurrentDBLevel(dB);
        }, 300);

        return () => clearInterval(interval);
    }, [permission, analyzerRef, recordingStatus]);

    const getMicrophonePermission = async () => {
        if ('MediaRecorder' in window) {
            try {
                const audioContext = new AudioContext();
                setAudioContext(audioContext);
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                const source = audioContext.createMediaStreamSource(streamData);
                const analyzer = audioContext.createAnalyser();
                analyzer.fftSize = 2048;
                source.connect(analyzer);
                analyzerRef.current = analyzer;
                setPermission(true);
                setStream(streamData);
            } catch (err: any) {
                alert(err.message);
            }
        } else {
            alert('The MediaRecorder API is not supported in your browser.');
        }
    };

    // Function to get decibel values from the audio
    const getDecibels = () => {
        const analyzer = analyzerRef.current;
        if (!analyzer) {
            return 0;
        }

        const dataArray = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(dataArray);

        let rms = 0;

        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i] > 120) dataArray[i] = 120;
            rms += dataArray[i] * dataArray[i];
        }
        rms = Math.sqrt(rms / dataArray.length);

        const offset = 30;

        const dB = rms + offset;

        console.log(`RMS: ${rms}`, `dB: ${dB}`);
        // const dB = 20 * Math.log10(average / 255);

        return dB;
    };

    const onClickBack = () => {
        setPage('form');
    };

    const onClickRecordButton = () => {
        if (recordingStatus == 'recording') {
            stopRecording();
        } else if (recordingStatus == 'inactive') {
            startRecording();
        }
    };

    const startRecording = () => {
        if (!stream) {
            notification.error(t('error.errorLoadingRecordStream'));
            return;
        }

        setRecordingStatus('recording');
        setRecordingDuration(0);
        setCurrentDBLevel(0);

        setMinDecibels(undefined);
        minDecibelsRef.current = undefined;

        setMaxDecibels(undefined);
        maxDecibelsRef.current = undefined;

        recordingDurationRef.current = 0;

        // create new Media recorder instance using the stream
        const media = new MediaRecorder(stream, { mimeType: mimeType });
        // set the MediaRecorder instance to the mediaRecorder ref
        mediaRecorder.current = media;
        // invokes the start method to start the recording process
        mediaRecorder.current.start();
        const localAudioChunks: Blob[] = [];

        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === 'undefined') return;

            if (event.data.size === 0) return;

            localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
    };

    const stopRecording = () => {
        setRecordingStatus('inactive');
        setCurrentDBLevel(0);

        if (!mediaRecorder.current) {
            notification.error(t('error.mediaRecorderError'));
            return;
        }

        // stops the recording instance
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            // creates a blob file from the audiochunks data
            const newAudioBlob = new Blob(audioChunks, { type: mimeType });
            // creates a playable URL from the blob file.
            const audioUrl = URL.createObjectURL(newAudioBlob);
            setAudio(audioUrl);
            console.log(newAudioBlob);            
            setAudioBlob(newAudioBlob);
            setAudioChunks([]);
        };
    };

    const onSave = async () => {
        if (!audio || !audioBlob) {
            notification.warn(t('t.pleaseMakeARecordingFirst'));            
            return;
        }
        
        try {
            if (!userContext?.userData) {
                throw new ComponentError(t('user.error.noUser'), 'ERROR');
            }

            const userData = userContext.userData;

            const currentDate = formatDate(new Date());

            const newReport: Partial<Report> = {
                ...partialReport,
                tms: currentDate,
                fkReportType: 'noise',
                fkUserCreator: userData.id,
                fkUserEdit: userData.id
            };

            const noiseReport: Partial<NoiseReport> = {
                duration: recordingDuration,
                maxDecibels: maxDecibels,
                minDecibels: minDecibels,
                fkReportType: 'noise',
                fkUserCreator: userData.id,
                fkUserEdit: userData.id
            };

            const saveComplete: Partial<SaveCompleteReport> = {
                reportData: newReport,
                reportTypeData: noiseReport
            };

            const result = await noiseReportController.createNoiseReport(saveComplete, audioBlob);

            console.log(result);

            await onSaveReport(partialReport, noiseReport, noiseReportController);
        } catch (e) {
            return handleComponentError(e, notification, t, t('report.error.errorSavingReport'));
        }
    };
        
    return (
        <div id="noise-page">
            <h2 className="title">
                {t('report.reportType.noiseReport')}
            </h2>
            <div className="audio-controls">
                {!permission && (
                    <MainButton
                        onClick={getMicrophonePermission}
                        classList={['main', 'fit-content']}
                    >
                        {t('t.activateMicrophone')}
                    </MainButton>
                )}
                {permission && (
                    <div id="recording-content">
                        <div id="button-container">
                            <MainButton
                                classList={[(recordingStatus == 'recording' ? 'main' : 'dark-gray'), 'round']}
                                className={'record-button'}
                                onClick={onClickRecordButton}
                            >
                                <FontAwesomeIcon icon={faMicrophone} />
                            </MainButton>
                            <div id="recording-status">
                                <div id="duration">
                                    {recordingDuration} s
                                </div>
                                <div id="decibels">
                                    {currentDBLevel} dBs
                                </div>
                            </div>
                        </div>
                        {!(recordingStatus == 'recording') && audio && (
                            <div id="recording-info">
                                <div id="decibels-info">
                                    <span>
                                        Max {maxDecibels} dBs
                                    </span>
                                    <span>
                                        Min {minDecibels} dBs
                                    </span>
                                </div>
                                <div className="audio-container">
                                    <audio src={audio} controls></audio>
                                    <a
                                        download
                                        href={audio}
                                        id="download"
                                        className='main-button main full-width'
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="actions">
                <MainButton
                    classList={['full-width', 'gray']}
                    onClick={onClickBack}
                >
                    {t('t.back')}
                </MainButton>
                <MainButton
                    classList={['full-width', 'main']}
                    onClick={onSave}
                >
                    {t('t.save')}
                </MainButton>
            </div>
        </div>
    );
};