import { ReactNode } from 'react';
import { FormProps } from 'react-router-dom';

const buttonClasses = [
	'full-width',
	'full-height',
	'no-shadow',
	'check',
	'red',
	'main',
	'gray',
	'dark-gray',
	'white',
	'border',
	'round',
	'no-fill',
	'model-ui back-button',
	'red-hover',
	'no-padding',
	'alt-hover',
	'plain-text',
	'yellow',
	'pre-alarm',
	'no-border-radius',
	'no-border',
	'fit-content'
] as const;

export type ButtonClass = (typeof buttonClasses)[number];

export type NotificationPopupStatus =
	| 'NORMAL'
	| 'ERROR'
	| 'WARNING'
	| 'LOADING';

export type NotifyProps = {
	type?: NotificationPopupStatus;
	text: string;
	closeType?: NotificationClose;
};
export type NotificationItem = {
	id: number;
	props: NotifyProps;
	item: ReactNode;
};
export type NotificationCloseType = 'timeout' | 'manual';
export type NotificationClose =
	{
		type: 'timeout';
		timeout: number;
	} | {
		type: 'manual';
	};

export type NotificationPopupProps = {
	notify: (props: NotifyProps) => void;
	notificationList: React.MutableRefObject<NotificationItem[]>;
};

export type ObjectImageProps = {
	src?: string;
	altSrc?: string;
};

export type FloorAction = 'equipment' | 'zone' | 'move' | 'select' | 'area' | 'area-square' | 'undo';

export type DrawingPointPoint = {
	pos: PosXY;
	highlight: boolean;
};

export type PosXY = { x: number; y: number };


export const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const ONE_MONTH = 1000 * 60 * 60 * 24 * 30;
export const ONE_MINUTE = 1000 * 60;
export const ONE_SECOND = 1000;

export const PLAZA_DE_BOLIVAR_POS = { lat: 4.81436, long: -75.69463 } as const;