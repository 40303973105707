import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { User } from '../Types/models.types';
import ModelController from './ModelController.controller';
import { KnownErrors } from '../Utils/components.utils';
import { ComponentError } from '../errors';
import { getTokenCookie } from '../Utils/cookies.utils';

export default class UserController extends ModelController<User> {
	constructor() {
		const objectName = 'user';

		super(objectName);
	}

	public login = async (userId: string, password: string) => {
		try {
			const config: AxiosRequestConfig = {};

			config.headers = {};

			const body = {
				userId: userId,
				password: password,
			};

			const response = await axios.post(
				`${this.api.url}/${this.objectName}/auth/login`,
				body,
				config
			);

			return response.data.data;
		} catch (e) {
			console.error('Error signin in:', e);

			if (e instanceof AxiosError) {
				const message = e.response?.data.message;

				let error: keyof typeof KnownErrors;

				switch (message) {
					case 'User not found':
						error = 'USER_NOT_FOUND';
						break;
					case 'Wrong password':
						error = 'WRONG_PASSWORD';
						break;
					default:
						error = 'UNKNOWN';
				}

				throw error;
			}

			throw e;
		}
	};

	public logout = async () => {
		try {
			const config: AxiosRequestConfig = {};

			const token = getTokenCookie();

			config.headers = {
				Authorization: `Bearer ${token}`,
			};

			await axios.delete(
				`${this.api.url}/${this.objectName}/auth/logout`,
				config
			);

			return true;
		} catch (e) {
			console.error('Error signing out:', e);

			return false;
		}
	};
}
