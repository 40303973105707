import React, { ReactNode, useState } from 'react';
import './MainModal.css';
import { MainButton } from '../Buttons/MainButton/MainButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type CommonMainModal = {
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
};

export type MainModalProps = CommonMainModal & {
    children?: ReactNode
    title?: string,
};

export const MainModal: React.FC<MainModalProps> = ({ children, title = '', modalState }) => {

    const [visible, setVisible] = modalState;

    const handleOnClickClose = () => {
        setVisible(false);
    };

    const handleClickOutside = (
        event: React.MouseEvent<HTMLDivElement>
    ) => {
        if (event.target == event.currentTarget) {
            setVisible(false);
        }

        return;
    };

    return (
        <div className={`main-modal ${visible ? 'visible' : ''}`} onClick={handleClickOutside}>
            <div className="modal-container">
                <div className="modal-top">
                    <div>
                        
                    </div>
                    <MainButton onClick={handleOnClickClose} classList={['red', 'no-border', 'no-shadow']} >
                        <FontAwesomeIcon icon={faTimes} />
                    </MainButton>
                </div>
                <div className="modal-content scroll">
                    {children}
                </div>
            </div>
        </div>
    );
};