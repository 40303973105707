import { NotificationPopupStatus } from './Types/components.types';


export class ComponentError extends Error {
    description: string;
    notificationStatus: NotificationPopupStatus;
    cause: any;

    constructor(description: string, notificationStatus: NotificationPopupStatus, cause?: any) {
        super();
        
        this.description = description;
        this.notificationStatus = notificationStatus;
        this.cause = cause;
    }
}