import './DashboardAlt.css';
import { faClock, faExclamation, faExclamationTriangle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import L, { HeatLatLngTuple } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { MainButton } from '../../Components/Buttons/MainButton/MainButton';
import { useContext, useEffect, useRef, useState } from 'react';
import { CivReport, CompleteReport, Report } from '../../Types/models.types';
import { handleComponentError } from '../../Utils/components.utils';
import { useNotification } from '../../Hooks/useNotification';
import NoiseReportController from '../../Controllers/Reports/NoiseReport.controller';
import 'leaflet.heat';
import { CompleteReportFilters } from '../../Controllers/Reports/CommonReport.controller';
import { PLAZA_DE_BOLIVAR_POS } from '../../Types/components.types';
import 'react-leaflet-fullscreen/styles.css';
import { Watson } from '../../Components/Watson/Watson';
import { MapSidebar, NoiseReportItem } from '../../Components/MapSidebar/MapSidebar';
import DashboardContext from '../../Context/DashboardContext';
import { MapStatistics } from '../../Components/MapStatistics/MapStatistics';
import UserContext from '../../Context/UserContext';
import { ReportModal } from '../../Components/Modals/ReportModal/ReportModal';
import { MainInput } from '../../Components/Inputs/MainInput/MainInput';
import { useFormInput } from '../../Hooks/useFormInput';
import ReportController from '../../Controllers/Reports/Report.controller';
import { startWithZeros } from '../../Utils/strings.utils';


ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

export const DashboardAlt = () => {
    const { t } = useTranslation();

    const notification = useNotification();

    const noiseReportController = new NoiseReportController();

    const [allReportsList, setAllReportsList] = useState<CompleteReport<CivReport>[]>([]);
    const [showMarkers, setShowMarkers] = useState(false);

    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);

    const dashboardContext = useContext(DashboardContext);

    dashboardContext.reportList = allReportsList;
    dashboardContext.setReportList = setAllReportsList;
    dashboardContext.showMarkers = showMarkers;
    dashboardContext.setShowMarkers = setShowMarkers;
    dashboardContext.startDate = startDate;
    dashboardContext.setStartDate = setStartDate;
    dashboardContext.endDate = endDate;
    dashboardContext.setEndDate = setEndDate;

    useEffect(() => {
        getAllReportsList();
    }, []);

    useEffect(() => {
        const refreshInterval = setInterval(async () => {
            await getAllReportsList();
        }, 1000 * 5);

        return () => clearInterval(refreshInterval);
    }, []);

    useEffect(() => {
        startDateRef.current = startDate;
        endDateRef.current = endDate;

        getAllReportsList();
    }, [startDate, endDate]);

    const getAllReportsList = async () => {
        try {
            const currentStartDate = startDateRef.current;
            const currentEndDate = endDateRef.current;

            const endDateObject = new Date(currentEndDate);

            const endDatePlusOne = new Date(endDateObject.getTime() + (1000 * 60 * 60 * 24));
            const endDateString = endDatePlusOne.toISOString().split('T')[0];

            const dateFilters: CompleteReportFilters<CivReport> = {
                report: [
                    ['tms', '>=', currentStartDate],
                    ['tms', '<=', endDateString]
                ],
                reportType: []
            };

            const noiseReportList = await noiseReportController.getCompleteObjectList(dateFilters);

            console.log(dateFilters);

            console.log(noiseReportList);

            const noiseReportListString = JSON.stringify(noiseReportList);
            const currentReportListString = JSON.stringify(allReportsList);

            if (noiseReportListString === currentReportListString) {
                console.log('no changes');

                return;
            }

            setAllReportsList(noiseReportList);
        } catch (e) {
            return handleComponentError(e, notification, t, 'noiseReport.error.errorGettingReportList');
        }
    };

    return (
        <DashboardContext.Provider value={dashboardContext}>
            <div className="dashboard-alt">
                <DashboardMap />
                {/* <Watson /> */}
            </div >
        </DashboardContext.Provider>
    );
};

/* const MapHandler = () => {
    const map = useMap();

    useEffect(() => {
        map.
    }, []);
} */

const DashboardMap = () => {
    const { reportList, showMarkers } = useContext(DashboardContext);

    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <MapContent />
            <MapStatistics />
            <div
                id="map"
                className={'rect-container base-shadow'}
                ref={containerRef}
            >
                <MapContainer
                    center={[PLAZA_DE_BOLIVAR_POS.lat, PLAZA_DE_BOLIVAR_POS.long]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{ minHeight: '100px', minWidth: '100px', height: '100%', width: '100%' }}
                >

                    {showMarkers && (
                        reportList.map((completeReport) => {
                            const { report } = completeReport;

                            return (
                                <Marker
                                    key={report.id}
                                    position={[report.latitude, report.longitude]}
                                >
                                    <Popup>
                                        <NoiseReportItem
                                            completeReport={completeReport}
                                            className='on-marker'
                                        />
                                    </Popup>
                                </Marker>
                            );
                        })
                    )}

                    <HeatMap />

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                </MapContainer>
            </div>
        </>
    );
};

export type CommonMapProps = {
    reportList: CompleteReport<CivReport>[]
};

const MapContent = () => {
    const userContext = useContext(UserContext);

    const userData = userContext?.userData;

    const notification = useNotification();

    const { t } = useTranslation();

    const { showMarkers, setShowMarkers, startDate, setStartDate, endDate, setEndDate } = useContext(DashboardContext);

    const [reportModalVisible, setReportModalVisible] = useState(false);

    const startDateInput = useFormInput(startDate);
    const endDateInput = useFormInput(endDate);

    useEffect(() => {
        setStartDate(startDateInput.getValue());
        setEndDate(endDateInput.getValue());
    }, [startDateInput.getValue(), endDateInput.getValue()]);

    const onClickReportNow = () => {
        if (!userData) {
            notification.warn(t('t.pleaseSignInFirst'));
            return;
        }

        setReportModalVisible(true);
    };

    return (
        <>
            <ReportModal
                modalState={[reportModalVisible, setReportModalVisible]}
            />
            <div className="map-actions">
                <div id="actions">
                    <ReportNowButton
                        onClickReportNow={onClickReportNow}
                    />
                    <MainButton
                        id='markers-button'
                        classList={[(showMarkers ? 'main' : 'gray'), 'full-height']}
                        onClick={() => setShowMarkers(!showMarkers)}
                    >
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </MainButton>
                </div>
                <div id="dates">
                    <MainInput
                        props={startDateInput}
                        type='date'
                        description={t('t.startDate')}
                        max={endDateInput.getValue()}
                    />
                    <MainInput
                        props={endDateInput}
                        type='date'
                        description={t('t.endDate')}
                        min={startDateInput.getValue()}
                    />
                </div>
            </div>
            <div className="map-content">
                <MapSidebar />
            </div>
        </>
    );
};


const HeatMap = () => {
    const { reportList } = useContext(DashboardContext);

    const map = useMap();

    const heatLayerRef = useRef<L.HeatLayer | null>(null);

    const reportDensity = 100 / reportList.length;
    const intensity = reportDensity > 20 ? 20 : reportDensity;

    useEffect(() => {
        const points = reportList.map((completeReport) => {
            const { report } = completeReport;

            const point: HeatLatLngTuple = [report.latitude, report.longitude, intensity];

            return point;
        });

        const heatLayer = L.heatLayer(points, { radius: 20 }).addTo(map);

        if (heatLayerRef.current) {
            heatLayerRef.current.remove();
        }

        heatLayerRef.current = heatLayer;

    }, [reportList]);

    return null;
};

type ReportNowButtonProps = {
    onClickReportNow: () => void
};
const ReportNowButton: React.FC<ReportNowButtonProps> = ({
    onClickReportNow
}) => {
    const { t } = useTranslation();

    const userContext = useContext(UserContext);
    const userData = userContext?.userData;

    const [userLastReport, setUserLastReport] = useState<Report>();
    const [reportAvailableTime, setReportAvailableTime] = useState(0);
    const [canReport, setCanReport] = useState(true);

    useEffect(() => {
        getUserLastReport();

        const interval = setInterval(() => {
            getUserLastReport();
        }, 1000 * 60);

        return () => clearInterval(interval);
    }, [userData]);

    useEffect(() => {
        if (!(reportAvailableTime > 0)) {
            return;
        }

        const interval = setInterval(() => {
            const newTime = reportAvailableTime - 1000;

            setReportAvailableTime(newTime);

            if (newTime <= 0) {
                setCanReport(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [reportAvailableTime]);

    useEffect(() => {
        if (!userLastReport) {
            setCanReport(true);
            return;
        }

        checkCanReport(userLastReport);
    }, [userLastReport]);

    const getUserLastReport = async () => {
        if (!userData) {
            return;
        }

        const reportController = new ReportController();

        const lastReport = await reportController.getUserLastReport(userData?.id);

        setUserLastReport(lastReport?.report);

        return lastReport;
    };

    const checkCanReport = (lastReport: Report) => {
        console.log(lastReport);

        const currentTime = new Date().getTime();
        const lastReportTime = new Date(lastReport.createdAt).getTime();

        const timeDiff = currentTime - lastReportTime;

        const timeDiffMinutes = timeDiff / 1000 / 60;

        console.log(currentTime, lastReportTime, timeDiffMinutes);

        if (timeDiffMinutes > 10) {
            setCanReport(true);
            return true;
        }

        setReportAvailableTime((1000 * 60 * 10) - timeDiff);
        setCanReport(false);
        return false;
    };

    const onClick = async () => {
        const lastReport = await getUserLastReport();

        if (!lastReport) {
            setCanReport(true);
            onClickReportNow();
            return;
        }

        const canReport = checkCanReport(lastReport.report);

        if (canReport) {
            onClickReportNow();
            return;
        }
    };

    const reportAvailableMinutes = Math.floor(reportAvailableTime / 1000 / 60);
    const reportAvailableSeconds = Math.floor(reportAvailableTime / 1000 % 60);

    return (
        <div id="report-now-button-container">
            {!canReport && (
                <span className='timer'>
                    <FontAwesomeIcon icon={faClock} className='icon' />
                    <span>
                        {startWithZeros(reportAvailableMinutes, 2)}:{startWithZeros(reportAvailableSeconds, 2)}
                    </span>
                </span>
            )}
            <MainButton
                classList={['main', 'full-height']}
                onClick={onClick}
                disabled={!canReport}
            >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {t('report.reportNow')}
            </MainButton>
        </div>
    );
};