export type Model =
	| User
	| ReportType
	| Report
	| CivReport
	| City
	| Department
	| Tag
	| ObjectTag;

export type CivReport =
	| NoiseReport;

export type CommonModel = {
	id: number;
	updatedAt: string;
	createdAt: string;
};

export type ModelCreated = CommonModel & {
	fkUserCreator: number;
	fkUserEdit: number;
};

export const Genders = {
	male: 'M',
	female: 'F',
	nonBinary: 'NB',
} as const;

export type Gender = (typeof Genders)[keyof typeof Genders];

export const isGender = (gender: string): gender is Gender => {
	return Object.values(Genders).includes(gender as Gender);
};

export const IDTypes = {
    CC: 'CC',
    CE: 'CE',
    TI: 'TI',
    TE: 'TE',
    NIT: 'NIT',
    PAS: 'PAS',
};

export type IDType = (typeof IDTypes)[keyof typeof IDTypes];

export const isIDType = (idType: string): idType is IDType => {
    return Object.values(IDTypes).includes(idType as IDType);
};

export type User = CommonModel & {
	personId: string;
    idType: IDType;
	name: string;
	lastName: string;
	email: string;
	phone: string;
	gender: Gender;
	birthday: string;
	address: string;
	neighborhodd: string;
	fkDepartment: number;
	fkCity: number;
    password: string;
};

export const ReportTypesT = {
	noise: 'noise',
} as const;

export type ReportTypeT = (typeof ReportTypesT)[keyof typeof ReportTypesT];

export const isReportType = (reportType: string): reportType is ReportTypeT => {
	return Object.values(ReportTypesT).includes(reportType as ReportTypeT);
};

export type ReportType = CommonModel & {
	code: ReportTypeT;
};

export type Report = ModelCreated & {
	fkReportType: ReportTypeT;
	tms: string;
	latitude: number;
	longitude: number;
	title: string;
	description: string;
};

export type CommonReport<T extends ReportTypeT> = ModelCreated & {
	fkReport: number;
	fkReportType: T;
};

export type NoiseReport = CommonReport<'noise'> & {
	duration: number;
	maxDecibels: number;
	minDecibels: number;
};

export type CompleteReport<T extends CivReport> = {
	report: Report;
	reportType: T;
	user: {
		id: string;
		name: string;
		lastName: string;
	}
};

export type Tag = ModelCreated & {
	name: string;
	objectType: string;
};

export type ObjectTag = ModelCreated & {
	fkTag: number;
	fkObject: number;
};

export type Department = CommonModel & {
	code: string;
	name: string;
};

export type City = CommonModel & {
	code: string;
	name: string;
	fkDepartment: number;
};
