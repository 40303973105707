import { createContext } from 'react';
import { CivReport, CompleteReport } from '../Types/models.types';

export type DashboardContextT = {
    reportList: CompleteReport<CivReport>[];
    setReportList: (reportList: CompleteReport<CivReport>[]) => void;
    showMarkers: boolean;
    setShowMarkers: (set: boolean) => void;
    startDate: string;
    setStartDate: (date: string) => void;
    endDate: string;
    setEndDate: (date: string) => void;
};

export default createContext<DashboardContextT>({
    reportList: [],
    setReportList: () => {
        return;
    },
    showMarkers: false,
    setShowMarkers: () => {
        return;
    },
    startDate: '',
    setStartDate: () => {
        return;
    },
    endDate: '',
    setEndDate: () => {
        return;
    }    
});